.modal-main {
    position: fixed;
    background: #2d2d2d;
    color: white;
    width: 60%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 15px;
    z-index: 10;
    height: 80vh;
    overflow-y: auto;
}

.modal-display-block {
    transition: opacity 0.5s ease-in;
    opacity: 1;
    visibility: visible;
    overflow-y: initial !important;
}

.modal-display-none {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.3s linear, opacity 0.3s linear;
}
