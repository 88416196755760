.input-box {
    background: #ffffff;
    border: 1px solid #b7b7b7;
    box-shadow: 2px 2px 19px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    width: 70%;
}

.art-in-pad-top {
    padding-top: 2%;
}

.art-in-marg-10 {
    /* margin-right: 10%; */
}

.art-in-horizontal-line {
    border-top: 1px solid #787676;
}

.art-in-input {
    background: #e0f9ef;
    border: 1px solid #14b2e3;
    border-radius: 5px;
    width: 55%;
}

.art-in-input:focus {
    outline: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    opacity: 1;
}
