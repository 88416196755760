.app-bg {
    background: linear-gradient(90deg, #f1fff9 0%, #effff9 99.91%);
    min-height: 100vh;
    min-width: 100vh;
}

.article-generate-btn {
    background: #40aba7;
    border-radius: 8px;
    color: white;
    padding: 0.5rem 1rem;
    border: none;
    margin-top: 3%;
}

button:disabled {
    border: none;
    background-color: #cccccc;
    color: #666666;
    border-radius: 8px;
    padding: 0.5rem 1rem;
    cursor: not-allowed;
}
